<template>
  <van-nav-bar
      left-arrow
      title="扫码"
      :border="false"
      @click-left="onClickLeft"
  />

  <div class="reader" id="reader"></div>
</template>

<script setup>
import { onBeforeUnmount } from 'vue'
import {Html5Qrcode} from "html5-qrcode";
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import { useStore } from 'vuex'
const router = useRouter()
const store = useStore()

let html5QrCode = null
let cameraId = null
// This method will trigger user permissions
Html5Qrcode.getCameras().then(devices => {
      /**
       * devices would be an array of objects of type:
       * { id: "id", label: "label" }
       */
      if (devices && devices.length) {
        if (devices.length === 1) {
          cameraId = devices[0].id;
        } else {
          cameraId = devices[1].id;
        }
      }

      html5QrCode = new Html5Qrcode("reader", /* verbose= */ true);
      startScan()
    }
).catch(err => {
  // handle err
});

let num = 0

const startScan = () => {
  html5QrCode.start(
      cameraId,
      {
        fps: 1,    // Optional, frame per seconds for qr code scanning
        qrbox: {width: 250, height: 250}  // Optional, if you want bounded box UI
      },
      (decodedText, decodedResult) => {
        if (decodedText.includes('order')) {
          store.commit('setIsScanSuccess', true)
          router.replace({ path: '/home', query: { data: JSON.stringify(decodedText) }} )
          stopScan()
        } else {
          Toast.fail(`请使用正确的二维码`)
        }
      },
      (errorMessage) => {
        num++

        if (num === 100) {
          Toast.fail('扫码失败')
          stopScan()
          router.push('/home')
        }
        console.log('errorMessage', errorMessage)
      })
      .catch((err) => {
      });
}

const stopScan = () => {
  html5QrCode.stop().then((ignore) => {
    // QR Code scanning is stopped.
    console.log('ignore', ignore)
  }).catch((err) => {
    // Stop failed, handle it.
  });
}

const onClickLeft = () => {
  router.push('/home')
}

onBeforeUnmount(() => {
  stopScan()
})
</script>

<style lang='less' scoped>
.reader {
  height: calc(100% - 46px);

  ::v-deep video {
    height: 100%;
    object-fit: cover;
  }

  ::v-deep #qr-shaded-region {
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #FFF;
      animation: upDown 5s ease infinite;
    }
  }
}

@keyframes upDown {
  0% { top: 0; }
  50% { top: 100% }
  100% { top: 0 }
}
</style>
